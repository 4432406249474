@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Rambla:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow&family=DM+Sans:opsz,wght@9..40,700&family=Heebo:wght@400;700&family=Inter&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  margin: 0;
  font-family: "Raleway";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #323232;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.position-relative {
  position: relative;
}
.text-black-1 {
  color: #323232;
}
.font-family-raleway {
  font-family: Raleway;
}
.font-500 {
  font-weight: 500;
}
.text-2xl {
  font-size: 22px;
}
.text-3xl {
  font-size: 36px;
}
.text-4xl {
  font-size: 46px;
}
section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.border-red-200 {
  border-color: #f79292;
}
.bg-red-100 {
  background-color: #fdf9f9;
}
.border-yellow-200 {
  border-color: #e8d277;
}
.bg-yellow-100 {
  background-color: #fefef9;
}
.border-pruple-200 {
  border-color: #8c8ce8;
}
.bg-pruple-100 {
  background-color: #f6f6fd;
}
.bg-sky {
  background-color: #fafafa;
}
.w-85 {
  width: 84%;
}
.border-grey-100 {
  border-color: #c0d6ec;
}
.border-grey-200 {
  border-color: #c2c2c2;
}
.bg-blue-400 {
  background-color: #46a0ee;
  border: 2px solid #46a0ee;
}

.section1--badge {
  position: absolute;
  left: 52%;
  top: 28%;
  height: 48px;
  rotate: -9deg;
  transform: translate(-55%, 0);
  border-radius: 3.04px;
}
.bg-grey-box {
  background: linear-gradient(
    180deg,
    rgba(225, 225, 225, 0.2) 0%,
    rgba(240, 240, 240, 0.32) 100%
  );
}

.section2-shadow {
  box-shadow: 34.29px 17.14px 68.57px 17.14px #0000000a;
  border-radius: 17.14px;
  background-color: #ffffff;
  /* box-shadow: 0px -8.57px 68.57px 0px #00000008; */
}
.bg-white {
  background: white;
}

.text-blue-100 {
  color: #11263c;
}
.font-Roboto {
  font-family: Roboto;
}
.font-rambla {
  font-family: rambla;
}
.text-gray-100 {
  color: #5c5c5c;
}
.text-gray-200 {
  color: #393c47;
}
.position-absolute {
  position: absolute;
}
.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
}
.container-animation {
  position: relative;
  /* overflow: hidden; */
}
.border-cs,
.bg-stone-300 {
  border-color: #c2c2c2;
}
.bg-hero-section {
  background: linear-gradient(
    98.49deg,
    rgba(70, 160, 238, 0.1) 3.04%,
    rgba(40, 91, 136, 0.1) 102.41%
  );
}
